import budownictwo from '../images/icons/engineer.svg'
import nieruchomosci from '../images/icons/building.svg'

import rodo from '../images/icons/checklist.svg'
import zamowieniaPubliczne from '../images/icons/checklist.svg'
import spolkiHandlowe from '../images/icons/connection.svg'

import prawoRodzinne from '../images/icons/family.svg'
import przestepstwaGospodarcze from '../images/icons/money.svg'
import podatki from '../images/icons/mortgage.svg'
import prosAndConsIcon from '../images/icons/pros-and-cons.svg'
import wypadkiDrogowe from '../images/icons/racing.svg'
import prawoPracy from '../images/icons/teamwork.svg'
import umowy from '../images/icons/document.svg'
import sporySadoweIarbitrazowe from '../images/icons/museum.svg'

import budownictwoGreen from '../images/icons/engineer.1.svg'
import nieruchomosciGreen from '../images/icons/building.1.svg'

import rodoGreen from '../images/icons/checklist.1.svg'
import zamowieniaPubliczneGreen from '../images/icons/checklist.1.svg'
import spolkiHandloweGreen from '../images/icons/connection.1.svg'

import prawoRodzinneGreen from '../images/icons/family.1.svg'
import przestepstwaGreen from '../images/icons/money.1.svg'
import podatkiGreen from '../images/icons/mortgage.1.svg'
import prosAndConsIconGreen from '../images/icons/pros-and-cons.1.svg'
import wypadkiDrogoweGreen from '../images/icons/racing.1.svg'
import prawoPracyGreen from '../images/icons/teamwork.1.svg'
import umowyGreen from '../images/icons/document.1.svg'
import sporySadoweGreen from '../images/icons/museum.1.svg'


import logo from '../images/logo.svg'
import arrowRight from '../images/icons/arrow-right.svg'
import arrowLeft from '../images/icons/arrow-left.svg'
import arrowRightGreen from '../images/icons/arrow-right-green.svg'
import arrowLeftGreen from '../images/icons/arrow-left-green.svg'
import phone from '../images/icons/phone.svg'
import mapPin from '../images/icons/map-pin.svg'
import mail from '../images/icons/mail.svg'

import facebook from '../images/icons/facebook.svg'
import twitter from '../images/icons/twitter.svg'
import linkedin from '../images/icons/linkedin.svg'

const icons = {

    arrowLeft,
    arrowRight,
    arrowLeftGreen,
    arrowRightGreen,
    phone,
    mapPin,
    mail,
    logo,
    budownictwo,
    zamowieniaPubliczne,
    nieruchomosci,
    spolkiHandlowe,
    umowy,
    przestepstwaGospodarcze,
    podatki,
    rodo,
    prawoPracy,
    sporySadoweIarbitrazowe,
    prawoRodzinne,
    wypadkiDrogowe,
    budownictwoGreen,
    zamowieniaPubliczneGreen,
    nieruchomosciGreen,
    spolkiHandloweGreen,
    umowyGreen,
    przestepstwaGreen,
    podatkiGreen,
    rodoGreen,
    prawoPracyGreen,
    sporySadoweGreen,
    prawoRodzinneGreen,
    wypadkiDrogoweGreen,
    prosAndConsIconGreen,
    prosAndConsIcon,
    facebook,
    twitter,
    linkedin

}
export default icons
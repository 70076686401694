import React, { useState, Component } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Link } from 'gatsby'

class EmailListForm extends Component{
    state = {
        email: '',
        status: ''
    }

    handleSubmit = (e) => {
        e.preventDefault();
        addToMailchimp(this.state.email)
        .then(data => {


          this.setState({
              email: '',
              status: 'Zostałeś pomyślnie zapisany do newslettera!'
          })
        })
        .catch(() => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
          // see below for how to handle errors
          this.setState({
            status: 'Coś poszło nie tak. Spróbuj ponownie później.'
        })
        })
      };

       handleEmailChange = (event) => {

        this.setState({email: event.currentTarget.value})
      };
    render() {

  return (
      <React.Fragment>
    <form onSubmit={this.handleSubmit} >
    <div>
        <input
        className="newsletter-input"
          placeholder="Adres e-mail"
          name="email"
          type="email"
          required
          onChange={this.handleEmailChange}
          value = {this.state.email}
        />
         <button type="submit">Subskrybuj</button>
         </div>
        <label htmlFor="agreement"> <input required type="checkbox" name="agreement" id="agreement"/>Administratorem Twoich danych jest ŚWIECKI Kancelaria Adwokacka Adam Świecki. W każdym czasie możesz wycofać zgodę. Dowiedz się więcej o ochronie Twoich <Link className=""to="/polityka-prywatnosci/">danych osobowych, prawach i celach przetwarzania.</Link></label>



    </form>
    <p>{this.state.status ? this.state.status : null}</p>
    </React.Fragment>
  );
    }
};

export default EmailListForm;
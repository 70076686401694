import React from 'react'
import '../styles/main.scss'
import mainLogo from '../images/logo-01.svg'
import Image from '../components/image'
import { Link } from 'gatsby'
import EmailListForm from './email-list-form'



 const Footer = (props) => (
    <footer className="footer">

        <div className="footer__contact">
            <div className="footer__container footer__container--white">
                <div className="footer__col footer__col--logo">
                    <img className="footer__logo" src={mainLogo} alt=""/>
                </div>
                <div className="footer__col">
                    <div className="footer__contact-info contact-info">
                        <Image type="icon" src="mapPin" alt=""/>
                        <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/PWcoAUXQTpYVC39z5"> <p className="contact-info__text">ul. Franczaka Lalka 43 <br/> 20-325 Lublin</p></a>
                    </div>
                    <div className="footer__contact-info contact-info">
                        <Image type="icon" src="mapPin" alt=""/>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/%C5%9AWIECKI+KANCELARIA+ADWOKACKA/@51.771243,19.4588677,15z/data=!4m5!3m4!1s0x0:0xc2a05ddc1d35215e!8m2!3d51.771243!4d19.4588677"> <p className="contact-info__text">ul. Narutowicza 16/1 <br/> 90-135 Łódź</p></a>
                    </div>
                        <div className="footer__contact-info contact-info">
                        <Image type="icon" src="phone" alt=""/>
                        <p className="contact-info__text contact-info__text--yellow"><a className="contact-info__mail--yellow" href="tel:+48 42 208 81 80">42 208 81 80</a></p>
                    </div>
                    <div className="footer__contact-info contact-info">
                        <Image type="icon" src="mail" alt=""/>
                        <p className="contact-info__text contact-info__text--yellow"><a className="contact-info__mail--yellow" href="mailto:kancelaria@swieckipuchacz.pl">kancelaria@swieckipuchacz.pl</a></p>
                    </div>
                    {/* <div className="footer__socials">
                        <a href="https://www.facebook.com/%C5%9Awiecki-Kancelaria-Adwokacka-100389794995391/" target="_blank">
                        <Image type="icon" src="facebook" alt=""/>
                        </a>

                        <a href="https://www.linkedin.com/in/adam-%C5%9Bwiecki-830133194/?originalSubdomain=pl" target="_blank">
                        <Image type="icon" src="linkedin" alt=""/>
                        </a>
                    </div>*/}
                    {/* <div className="footer__newsletter newsletter">
                        <p>Zapisz się do newslettera</p>
                    <EmailListForm />
                        </div> */}
                </div>
                <div className="footer__col">
                <ul className="footer__menu menu">
                    <li className="menu__item"><Link className="menu__link"to="/oferta/">Oferta</Link></li>
                    <li className="menu__item"><Link className="menu__link"to="/zespol/">Zespół</Link></li>
                    <li className="menu__item"><Link className="menu__link"to="/klienci/">Zaufali nam</Link></li>
                    <li className="menu__item"><Link className="menu__link"to="/polityka-prywatnosci/">Polityka prywatności</Link></li>
                    <li className="menu__item"><Link className="menu__link"to="/cookies/">Polityka cookies</Link></li>
                    <li className="menu__item"><Link className="menu__link"to="/kontakt/">Kontakt</Link></li>
                </ul>
            </div>
            </div>
        </div>
        <div className="footer__copyright-info">
            <div className="footer__container footer__container--grey">
                <p className="footer__copyright-text">2021. All right reserved. Created by <a target="_blank" href="https://osomstudio.com/"><span className="footer__yellow-text">Osom
                        Studio </span></a> </p>
            </div>
        </div>
    </footer>

)

export default Footer
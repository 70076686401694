import logo25 from '../images/loga/25.png'
import logoBankier from '../images/loga/bankier.png'
import logoBudomal from '../images/loga/budomal.png'
import logoCeint from '../images/loga/ceint.png'
import logoGazinstal from '../images/loga/gazinstal.png'
import logoHerb1 from '../images/loga/herb_01.png'
import logoHerb2 from '../images/loga/herb_02.png'
import logoHerb3 from '../images/loga/herb_03.png'
import logoInteinst from '../images/loga/inteinst.png'
import logoKopij from '../images/loga/kopij.png'
import logoKuza from '../images/loga/kuza.png'
import logoLdt from '../images/loga/ldt.png'
import logoMgbud from '../images/loga/mgbud.png'
import logoOlechow from '../images/loga/nowyolechow.png'
import logoOsom from '../images/loga/osom.svg'
import logoOzorkow from '../images/loga/ozorkow.png'
import logoPiotrek from '../images/loga/piotrektome.png'
import logoPlantech from '../images/loga/plantech.png'
import logoAkacje from '../images/loga/podakacjami.png'
import logoPzp from '../images/loga/pzp.png'
import logoSlowosfera from '../images/loga/slowosfera.png'
import logoStanpol from '../images/loga/stanpol.png'
import logoSteelmax from '../images/loga/steelmax.png'
import logoTargetex from '../images/loga/targetex.png'
import logoZgierz from '../images/loga/zgierz.png'
import logoPrzeclaw from '../images/loga/przeclaw.png'
import logoJenerixRed from '../images/loga/jenerix-red.png'
import logoPromat from '../images/loga/promatbud.png'

import logoAbton from '../images/loga/abton.png'
import logoBychawa from '../images/loga/bychawa.jpg'
import logoEvitum from '../images/loga/evitum.png'
import logoFirma from '../images/loga/firma.png'
import logoForest from '../images/loga/forest.jpg'
import logoGse from '../images/loga/gse.png'
import logoIness from '../images/loga/iness.jpg'
import logoNiedz from '../images/loga/niedz-new.png'
import logoOrzel from '../images/loga/orzel-new.png'
import logoOrzel2 from '../images/loga/orzel2-new.png'
import logoSpot from '../images/loga/spot.png'
import logoWiew from '../images/loga/wiew-new.png'




const logos = {
    logoPzp,
    logoBudomal,
    logoZgierz,
    logoBankier,
    logoOsom,
    logoLdt,
    logoPrzeclaw,
    logoHerb1,
    logoJenerixRed,
    logo25,
    logoPlantech,
    logoOzorkow,
    logoPiotrek,
    logoCeint,
    logoInteinst,
    logoTargetex,
    logoHerb2,
    logoHerb3,
    logoKuza,
    logoAkacje,
    logoKopij,
    logoStanpol,
    logoMgbud,
    logoOlechow,
    logoSlowosfera,
    logoGazinstal,
    logoSteelmax,
    logoPromat,
    logoAbton,
    logoBychawa,
    logoEvitum,
     logoFirma,
 logoForest,
 logoGse,
 logoIness,
 logoNiedz,
 logoOrzel,
 logoOrzel2,
 logoSpot,
 logoWiew
}
export default logos
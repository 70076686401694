const htmlEntities = {
    nbsp: ' ',
    cent: '¢',
    pound: '£',
    yen: '¥',
    euro: '€',
    copy: '©',
    reg: '®',
    lt: '<',
    gt: '>',
    laquo: '«',
    raquo: '»',
    quot: '"',
    amp: '&',
    apos: "'",
    divide: '÷',
    lowast: '∗',
    minus: '−',
    infin: '∞',
    asymp: '≈',
    ne: '≠',
    equiv: '≡',
    le: '≤',
    ge: '≥',
    circ: 'ˆ',
    OElig: 'Œ',
    oelig: 'œ',
    tilde: '˜',
    ndash: '–',
    mdash: '—',
    lsquo: '‘',
    rsquo: '’',
    sbquo: '‚',
    ldquo: '“',
    rdquo: '”',
    bdquo: '„',
    dagger: '†',
    bull: '•',
    hellip: '…',
    permil: '‰',
    prime: '′',
    Prime: '″',
    lsaquo: '‹',
    rsaquo: '›',
    trade: '™',
    larr: '←',
    uarr: '↑',
    rarr: '→',
    darr: '↓',
  }

const utils = {
    removePolishSigns: (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/ł/g, "l")
    },
    splitIntoWords: (str) => {

        if(str === 'SporySądoweIarbitrazowe') {
            return 'Spory Sądowe i Arbitrażowe'
        } else {
        return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
        }
    },
    capitalizeFirstLetter: (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1)
    },
    chunkArray: (arr, size) => {
        const results = [];

        while (arr.length) {
            results.push(arr.splice(0, size));
        }

    return results;
    },

    camelize: (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
            return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
          }).replace(/\s+/g, '');
    },

    camelToDash: (str) => {

        if(str === 'sporySadoweIarbitrazowe') {
            return 'spory-sadowe-i-arbitrazowe'
        } else {
        return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()
        }
    },

     unescapeHTML: (str) => {
        return str.replace(/&([^;]+);/g, (entity, entityCode) => {
          let match

          if (entityCode in htmlEntities) {
            return htmlEntities[entityCode]
            /* eslint no-cond-assign: 0 */
          }
          if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
            return String.fromCharCode(parseInt(match[1], 16))
            /* eslint no-cond-assign: 0 */
          }
          if ((match = entityCode.match(/^#(\d+)$/))) {
            // eslint-disable-next-line no-bitwise
            return String.fromCharCode(~~match[1])
          }
          return entity
        })
      }


  }

export default utils
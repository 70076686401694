import React from 'react'
import '../styles/main.scss'
import icons from '../js/icons'
import logos from '../js/logos'
import PropTypes from 'prop-types'

const Image = (props) => {
    if (props.type === 'icon') {
        return (<img src={icons[props.src]} alt={props.alt} className={props.class}/>)
    } else if (props.type === 'logo') {
        return (<img src={props.src} alt={props.alt} className={props.class}/>)
    }

}

  Image.propTypes = {
    type: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    class: PropTypes.string
  }

export default Image